import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  HStack,
  Input,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik, Field, FieldArray } from "formik";
import * as React from "react";
import { BiEdit, BiSave, BiTaskX, BiX, BiPrinter } from "react-icons/bi";
import {
  courseBgColor,
  courseFacultativeStripes,
  courseTextColor,
} from "../../Forms/Course/courseBgColor";
import Debug from "../../Forms/Debug";
import { InscriptionUpdateModal } from "../../Inscription/UpdateModal";
import { config } from "../../Utils/Config";
import { PromoNoteGrid } from "./Grid";
import { PastingTool } from "./PastingTool";
import { UserContext } from "../../Auth/userContext";
var lodash = require("lodash");

export const NotesForm = ({
  programId,
  coursePlanId,
  startYear,
  endYear,
  handleClose,
  year,
}) => {
  const userContext = React.useContext(UserContext);
  let { user } = userContext;
  // console.log('user', user);
  console.log("user", user);

  const [data, setData] = React.useState();
  const [coursePlan, setCoursePlan] = React.useState();

  const [editInscriptionId, setEditInscriptionId] = React.useState();
  const [notificationsAsked, setNotificationsAsked] = React.useState(false);
  const [notesSavedOnce, setNotesSavedOnce] = React.useState(false);

  const toast = useToast();
  React.useEffect(() => {
    axios
      .get(
        `${config.apiUrl}/inscription/${programId}/${coursePlanId}/${startYear}/${endYear}/all`
      )
      .then((res) => {
        // console.log('data', res.data);
        setData(res.data);
      });

    axios.get(`${config.apiUrl}/course-plan/${coursePlanId}/`).then((res) => {
      // console.log('data', res.data);
      setCoursePlan(res.data);
    });
  }, []);

  const formatNotes = (data) => {
    let index = 0;
    var formatedNotesReturn = {};

    let inscriptionWithNotes = data.filter((item) => item.notes.length !== 0); // we only keeps inscription with notes
    while (index < inscriptionWithNotes.length) {
      // only inscriptions with note associated

      const inscription = inscriptionWithNotes[index];
      // console.log('inscription', inscription)

      let yearNotes = []; // year notes is a filter to keep only the array of notes corresponding to year
      yearNotes = inscription.notes.filter((item) => item.year === year).length
        ? inscription.notes.filter((item) => item.year === year)[0].values
        : [];

      if (yearNotes && yearNotes.length) {
        formatedNotesReturn[inscription._id] = {}; // set the appropriate schema for formik
        let index2 = 0;
        while (index2 < yearNotes.length) {
          const note = yearNotes[index2];
          formatedNotesReturn[inscription._id][note.course] = note.note;
          index2++;
        }
      }

      index++;
    }

    return formatedNotesReturn;
  };

  const coursesYearFilter = (year) => {
    let courses = coursePlan.courses.filter(
      (course) => course.course?.startSemester && course.course?.endSemester
    );

    let periodStartSemester: Number = year * 2 - 1;
    let periodEndSemester: Number = year * 2;

    let filteredCourses = courses.filter((item) => {
      // console.log(course.startSemester, course.endSemester)
      // console.log('course', item.course);

      var semesterslist = [];

      //   if (!item.course) console.log("item pourri", item);
      //   if (!item.course?.startSemester){ continue; }

      for (
        var i = item.course.startSemester;
        i <= item.course.endSemester;
        i++
      ) {
        semesterslist.push(i);
      }
      // console.log(semesterslist);
      if (
        ((item.course.startSemester - periodStartSemester) ^
          (item.course.startSemester - periodEndSemester)) <
          0 ||
        semesterslist.includes(periodStartSemester) ||
        semesterslist.includes(periodEndSemester)
      ) {
        return true;
      } else {
        return false;
      }
      //
    });

    // console.log('filteredCourses', filteredCourses.length, 'année' + year)

    return filteredCourses;
  };

  const RequiredStar = ({ isMandatory }) =>
    isMandatory ? (
      <Text as="sup" color="red">
        *
      </Text>
    ) : null;

  // return <Debug data={coursePlan} />

  const handleDeleteNote = (setFieldValue, index) => {
    // console.log(`handleDeleteNote`, index)
    // setFieldValue(`notes.637634ebf117cc6d944a4f50`, {})
  };

  const marginToClassAverage = (ratio, notes, course) => {
    let calculation =
      Math.ceil(
        100 *
          ratio *
          lodash.mean(
            Object.keys(notes)
              .map((x) => parseInt(notes[x][course._id]))
              .filter((note) => note > 0)
          )
      ) / 100;
    return calculation;
  };

  const handleTranscriptsNotifications = async () => {
    axios
      .post(
        `${config.apiUrl}/inscription/${programId}/${coursePlanId}/${startYear}/${endYear}/send-notification`,
        { user: user }
      )
      .then((result) => setNotificationsAsked(true));
  };

  return (
    <>
      <InscriptionUpdateModal
        inscriptionId={editInscriptionId}
        handleClose={() => setEditInscriptionId(null)}
        // handleClose={() => console.log('close modal')}
      />
      {data && coursePlan ? (
        <Formik
          initialValues={{
            notes: formatNotes(data),
            year: year,
            program: programId,
            coursePlan: coursePlanId,
            startYear: startYear,
            endYear: endYear,
          }}
          onSubmit={(values, actions) => {
            axios
              .post(`${config.apiUrl}/inscription/note`, values)
              .then((res) => {
                actions.setSubmitting(false);
                setNotesSavedOnce(true);
                toast({
                  title: "Notes saved",
                  description: `All this notes has been saved.`,
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Debug data={values.notes} />
              <Flex
                // alignSelf={'flex-start'}
                justify="space-between"
                // py={10}
                my={2}
                py={2}
                w="full"
                borderBottom="solid 1px"
                borderBottomColor="gray.200"
              >
                <Heading
                  fontSize={"sm"}
                  fontWeight={500}
                  textAlign="left"

                  // p={2}
                  // mb={4}
                >
                  Academic Year :{" "}
                  {`${parseInt(startYear) + year - 1}/${
                    parseInt(startYear) + year
                  }`}{" "}
                  {}; Semesters{" "}
                  {`${(year - 1) * 2 + 1} & ${(year - 1) * 2 + 2}`}
                </Heading>

                <ButtonGroup size="xs">
                  <Button
                    as={`a`}
                    target={`_blank`}
                    href={`${config.apiUrl}/inscription/promotion-note-matrice/${programId}/${coursePlanId}/${startYear}/${endYear}/${year}`}
                  >
                    Notes matrix example
                  </Button>
                  <PastingTool
                    handleImport={(importedNotes) => {
                      for (let x = 0; x < data.length; x++) {
                        const inscriptionId = data[x]._id;
                        for (
                          let y = 0;
                          y < coursesYearFilter(year).length;
                          y++
                        ) {
                          const courseId =
                            coursesYearFilter(year)[y].course._id;
                          setFieldValue(
                            `notes.${inscriptionId}.${courseId}`,
                            importedNotes[x][y]
                          );
                        }
                      }
                    }}
                    linesNumber={data.length}
                    colsNumber={coursesYearFilter(year).length}
                  />
                </ButtonGroup>
              </Flex>
              <Box overflowX={`scroll`} pb={10}>
                {/* <Debug
                                    data={values}
                                    key={Object.keys(values.notes).length}
                                /> */}
                {/* <div>notes : {Object.keys(values.notes).length}</div> */}
                <Grid
                  gridTemplateColumns={`350px repeat(${
                    coursesYearFilter(year).length
                  }, minmax(200px, 200px))`}
                  gap={2}
                  w={`${coursesYearFilter(year).length * 200 + 350}px`}
                >
                  <Box bg={`gray.50`} position={`sticky`} left={0} />
                  {coursesYearFilter(year).map((item) => (
                    <Box
                      // textAlign={'center'}
                      key={`course-label-${item.course._id}`}
                      fontSize="sm"
                      fontWeight="600"
                      textAlign={"center"}
                      rounded="md"
                      {...courseBgColor(item.course.type, true)}
                      {...courseTextColor(item.course.type)}
                      title={`${item.course.type ? item.course.type : ``} / ${
                        item.course.passing.system
                      } (${
                        item.course.isMandatory ? "mandatory" : "facultative"
                      })`}
                    >
                      {item.course.name}
                      <br />
                      {item.course.code}
                      {/* <Text as='span' fontSize='xs'>({item.course.passing.system})</Text> */}
                      {/* <RequiredStar isMandatory={item.course.isMandatory} /> */}
                    </Box>
                  ))}

                  <FieldArray
                    name="notes"
                    render={(arrayHelpers) =>
                      data.map((inscription, index) => (
                        <React.Fragment key={`inscription-line-${index}`}>
                          <Flex
                            flex={1}
                            alignItems={"center"}
                            justify="space-between"
                            fontSize={"sm"}
                            borderBottom={"solid 1px"}
                            borderBottomColor={"gray.300"}
                            px={2}
                            minW={`350px`}
                            maxW={`350px`}
                            position={`sticky`}
                            left={0}
                            zIndex={`tooltip`}
                            bg={`gray.50`}
                          >
                            {/* <pre>
                                                            {JSON.stringify(inscription, null, 0)}
                                                        </pre> */}
                            <Box
                              whiteSpace={`pre`}
                              maxW={`100%`}
                              pr={8}
                              overflow={`hidden`}
                              textOverflow={`ellipsis`}
                              textDecor={
                                inscription.status != `archived`
                                  ? ``
                                  : "line-through"
                              }
                              opacity={
                                inscription.status != `archived` ? 1 : 0.5
                              }
                            >
                              {`${inscription.student.firstName} `}
                              <Text
                                as="span"
                                fontWeight="600"
                                textTransform={`uppercase`}
                              >
                                {`${inscription.student.lastName}`}
                              </Text>
                            </Box>

                            <Button
                              leftIcon={<BiEdit />}
                              size="xs"
                              variant="outline"
                              flexShrink={0}
                              onClick={() =>
                                setEditInscriptionId(inscription._id)
                              }
                            >
                              Edit
                            </Button>
                          </Flex>

                          {coursesYearFilter(year).map((item, index) => (
                            <Box
                              {...courseFacultativeStripes(
                                item.course.isMandatory
                              )}
                              key={`notes.${inscription._id}-${index}`}
                            >
                              <Field
                                name={`notes.${inscription._id}.${item.course._id}`}
                                // key={`notes.${inscription._id}.${item.course._id}`}
                                // key={field.value}
                                // name={`${inscription._id}_${course._id}`}
                              >
                                {({ field, meta }) => (
                                  <Flex
                                  // key={field.value}
                                  >
                                    {/* <Debug data={item.course} /> */}
                                    <Input
                                      {...field}
                                      id={`notes.${inscription._id}-${index}-field`}
                                      key={`notes.${inscription._id}-${index}-field`}
                                      size="sm"
                                      disabled={
                                        item.course.status === "inactive"
                                      }
                                      placeholder={
                                        item.course.passing.system ===
                                        `passOrFail`
                                          ? `0/1`
                                          : item.course.highestNote
                                          ? `${item.course.lowestNote}/${item.course.highestNote}`
                                          : ""
                                      }
                                      variant={"flushed"}
                                      textAlign="center"
                                      // autoComplete="off"
                                      bgColor={
                                        field.value || field.value === 0
                                          ? "green.50"
                                          : meta.touched && !field.value
                                          ? "orange.50"
                                          : "transparent"
                                      }
                                      color={
                                        field.value || field.value === 0
                                          ? "green.800"
                                          : "gray.900"
                                      }
                                      opacity={
                                        item.course.status === "inactive"
                                          ? 0.5
                                          : 1
                                      }
                                    />
                                    <Button
                                      onClick={() => {
                                        let currentNotes = values.notes;
                                        delete currentNotes[inscription._id][
                                          item.course._id
                                        ];
                                        // console.log(`#${`notes.${inscription._id}-${index}-field`}`)
                                        // console.log(document.getElementById(`#${`notes.${inscription._id}-${index}-field`}`))
                                        setFieldValue(
                                          `notes`,
                                          currentNotes,
                                          false
                                        );
                                        document.getElementById(
                                          `${`notes.${inscription._id}-${index}-field`}`
                                        ).value = ``;
                                      }}
                                      ml={1}
                                      size={`sm`}
                                    >
                                      <BiX />
                                    </Button>
                                  </Flex>
                                )}
                              </Field>
                            </Box>
                          ))}
                        </React.Fragment>
                      ))
                    }
                  />

                  <Box bgColor={`gray.50`} position={`sticky`} left={0} />

                  {coursesYearFilter(year).map((item, index) => (
                    <Text
                      fontSize="sm"
                      textAlign={"center"}
                      bgColor="gray.400"
                      color="white"
                      fontSize="sm"
                      rounded="md"
                      textShadow={"xl"}
                      key={`coursesYearFilter-${index}`}
                    >
                      Passing limite :{" "}
                      <strong>
                        {item.course.passing.system === "marginToClassAverage"
                          ? marginToClassAverage(
                              item.course.passing.ratio,
                              values.notes,
                              item.course
                            ) < item.course.passing.value
                            ? marginToClassAverage(
                                item.course.passing.ratio,
                                values.notes,
                                item.course
                              )
                            : item.course.passing.value
                          : // Math.ceil(
                            //     100 *
                            //     item.course.passing.ratio
                            //     *
                            //     lodash.mean(Object.keys(values.notes).map(x => parseInt(values.notes[x][item.course._id])).filter(note => note > 0))
                            // ) / 100
                            item.course.passing.value}
                      </strong>
                      {/* <Debug data={Object.keys(values.notes).map(x => parseInt(values.notes[x][item.course._id])).filter(note => note > 0)} /> */}
                    </Text>
                  ))}
                </Grid>
              </Box>

              <Flex my={10} justify={`space-between`}>
                <HStack>
                  <Button
                    type="submit"
                    variant={"outline"}
                    leftIcon={<BiSave />}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                  {/* <Text
                                        fontSize='sm'
                                        color={'gray.500'}
                                    >
                                        Each student must have a note for each mandatory subject.
                                    </Text> */}

                  <Box>
                    {!notificationsAsked ? (
                      <Button
                        variant={"outline"}
                        leftIcon={<BiPrinter />}
                        title={
                          !notesSavedOnce
                            ? `Fill and save notes before`
                            : `Your request will be forwarded to EABHES staff.`
                        }
                        disabled={!notesSavedOnce}
                        onClick={() => {
                          handleTranscriptsNotifications();
                        }}
                      >
                        Receive transcripts
                      </Button>
                    ) : (
                      <Text color={`gray.500`} fontSize="sm">
                        Your request has been forwarded to the team, you'll
                        receive the transcripts soon.
                      </Text>
                    )}
                  </Box>
                </HStack>
              </Flex>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};
