import {
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Table,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import dayjs from "dayjs";
import * as React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import Debug from "../Forms/Debug";
import { Loader } from "../UI/Loader";
import { config } from "../Utils/Config";
import { TableActions } from "./ListHeader";
import { CgGenderFemale, CgGenderMale } from "react-icons/cg";
import { ProfilPict } from "../Components/ProfilPict/View";
import { UserContext } from "../Auth/userContext";
import { NoResults } from "../UI/NoResults";
import { TablePagination } from "../School/ListPagination";
import { scrollToTopContent } from "../Utils/scrollToTopContent";
import { BackButton } from "../UI/BackButton";

export const StudentsList = ({ view, page }) => {
  const params = useParams();

  const { user } = React.useContext(UserContext);
  const [data, setData] = React.useState();

  const [currentPage, setCurrentPage] = React.useState(params.page);
  const [lastPage, setLastPage] = React.useState();
  const [totalResults, setTotalResults] = React.useState(0);

  const [search, setSearch] = React.useState();

  React.useEffect(() => {
    // if (!data) {
    getData();
    // }
  }, [params.page, search]);

  React.useEffect(() => {
    getData();
  }, [params.page, search]);

  const getData = () => {
    axios
      .get(
        user.role === "admin" && !params.schoolId
          ? `${config.apiUrl}/student/${params.page ? params.page : `1`}/all/${
              search && search.length ? `${search}` : "-"
            }`
          : user.role === "admin" && params.schoolId
          ? `${config.apiUrl}/student/school/${params.schoolId}/?search=${
              search ?? ``
            }&page=${page ?? ``}`
          : user.role === "user" && search
          ? `${config.apiUrl}/student/school/all/${
              user.school
            }/?search=${search}&page=${params.page ?? `1`}`
          : `${config.apiUrl}/student/school/${user.school}?page=${
              params.page ?? `1`
            }`
      )
      .then((result) => {
        setData(result.data);
        setLastPage(parseInt(result.headers.lastpage));
        setCurrentPage(parseInt(result.headers.currentpage));
        setTotalResults(parseInt(result.headers.results));

        if (view === "admin") scrollToTopContent();
      });
  };

  return (
    <>
      <Stack>
        <Heading id="top" mb={4}>
          Students
        </Heading>

        {view === `admin` && params.schoolId && (
          <BackButton label="Back to school details" />
        )}

        {(user.role === "admin" || user.role === `user`) && (
          <TableActions
            getValues={(values) => setSearch(values.search)}
            view={view}
          />
        )}

        {data ? (
          data.length ? (
            <Table
              mt="8"
              mb={2}
              borderWidth="1px"
              fontSize="sm"
              bgColor="white"
            >
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Code</Th>
                  <Th>Birth</Th>
                  <Th textAlign="right">Action</Th>
                </Tr>
              </Thead>

              {data.map((item) => (
                <Tr>
                  <Td>
                    <HStack>
                      {item.profilPict ? (
                        <ProfilPict
                          filePath={item.profilPict.document}
                          size="32px"
                        />
                      ) : null}
                      <Text>
                        {`${item.firstName} ${item.lastName.toUpperCase()}`}
                      </Text>

                      {/* {item.gender === 'm'
                                        ?
                                        <CgGenderMale />
                                        : item.gender === 'f' ?
                                            <CgGenderFemale />
                                            : null
                                    } */}
                    </HStack>
                  </Td>
                  <Td>{item.code}</Td>
                  <Td>
                    {item.birth.date
                      ? `${dayjs(item.birth.date).format("YYYY")} (${
                          item.birth.city
                        })`
                      : "-"}
                  </Td>
                  <Td textAlign={"right"}>
                    <HStack justify={"flex-end"}>
                      {view === `admin` ? (
                        <Button
                          size="sm"
                          variant="link"
                          as={Link}
                          to={`/${
                            view === `admin` ? `admin` : `account`
                          }/inscription/student/${item._id}`}
                          colorScheme={"blue"}
                        >
                          Inscriptions
                        </Button>
                      ) : null}

                      <Button
                        size="sm"
                        variant="link"
                        as={Link}
                        to={`/${
                          view === `admin` ? `admin` : `account`
                        }/student/view/${item._id}`}
                        colorScheme={"blue"}
                      >
                        Details
                      </Button>
                    </HStack>
                    {/* Update */}
                  </Td>
                </Tr>
              ))}
            </Table>
          ) : (
            <NoResults label="No student found" />
          )
        ) : (
          <Loader label="Loading students" />
        )}

        {totalResults && lastPage && currentPage ? (
          <>
            <TablePagination
              totalResults={totalResults}
              lastPage={lastPage}
              currentPage={currentPage}
              baseLink={
                view === "user"
                  ? "/account/student/"
                  : params.schoolId
                  ? `/admin/school/${params.schoolId}/students/`
                  : "/admin/student/"
              }
              itemName={`student`}
            />
          </>
        ) : null}

        {/* <Debug data={[totalResults, lastPage, currentPage]} /> */}
      </Stack>
    </>
  );
};

// phoneNumber
// birthDate
// birthPlaceCity
// birthPlaceCountry
